body {
  margin: 0;
  padding: 0;
  font-family: 'Actor', sans-serif;
  /* good minimum font size for accessibility */
  font-size: 16px;
}

h2 {
  margin-top: 60px;
}
